import * as React from "react";
import "./hero.css";

const SVGComponent = (props) => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 70.82 92.28'
    {...props}>
    <path
      d='M19.33,99l5.76,4.91a1.19,1.19,0,0,0,1.69-.13h0l4.91-5.76a1.2,1.2,0,0,0-1.82-1.56h0l-3,3.56c-1.66-16.78,6.23-28.15,14.74-35.38a33.49,33.49,0,0,0,5.9,1.71c17.07,3.39,36.86-2,41.07-8.88,2.12-3.43.94-5.64,0-6.65C84.3,46,68.77,47.27,54.67,53.65a63.73,63.73,0,0,0-13.58,8.17c-2.88-1.43-5.39-3.57-5.9-6.77C34,47.5,44.89,43.12,55.45,38.89c10-4,19.5-7.83,19.82-14.66C75.74,13.81,57.92,12,57.16,12H57a1.2,1.2,0,0,0,0,2.39c.17,0,16.31,1.63,15.94,9.78-.24,5.29-9.43,9-18.31,12.54C43.12,41.25,31.3,46,32.82,55.43c.53,3.35,2.66,6.07,6.26,8.08a42.74,42.74,0,0,0-14.64,36.72l-3.55-3A1.2,1.2,0,1,0,19.33,99ZM55.66,55.84c13.73-6.22,28-6.93,31.13-3.39.3.34,1.21,1.37-.29,3.8-2.76,4.48-13,7.79-23.82,8.55A57.41,57.41,0,0,1,47.93,64a33.11,33.11,0,0,1-4.16-1.11A61.52,61.52,0,0,1,55.66,55.84Z'
      transform='translate(-18.91 -11.95)'
    />
  </svg>
);
export default SVGComponent;
