import * as React from "react";

const SVGComponent = (props) => (
  <svg
    id='Layer_3'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 57.46 88.86'
    {...props}>
    <path
      d='M37.29,30c-1.82,1.1-3.56,2.28-5.18,3.46-3.18,2.31-6.5,5.45-6.55,9.67C25.49,49.41,30.63,54.26,36,57.46c-7.44,6-11.9,14-6.94,24a37.57,37.57,0,0,0,9.16,11.61l-11.2-2.67a2.22,2.22,0,1,0-1.14,4.29l16.82,4c1.6.38,3.5-.61,2.9-2.52L40.46,79.67c-.85-2.71-5.61-2.3-4.65.74l3.3,10.53c-8.19-6-13.12-15.54-5.56-24.63a37.15,37.15,0,0,1,7.15-6.49l.42.19C50.56,64,63.1,66.28,73,62.67,79.85,60.18,85.56,52.45,78,47c-3.69-2.64-9.21-2.25-13.46-1.72a59.42,59.42,0,0,0-22.45,8c-1.19.7-2.34,1.44-3.46,2.22-1.38-.62-2.66-1.25-3.79-1.89-2.32-1.32-5.78-3.18-6.37-6C27.7,44.23,30.64,41,33,39a61.92,61.92,0,0,1,7.37-5.25c7.13,7.31,17.72,10.51,21.68,1.4,2.71-6.22-4.38-11-9.79-10.62-4.73.33-9.42,2.25-13.7,4.7-2.91-4.52-3.29-10.34.89-15,.87-1-2.16-4.74-2.55-4.3C31.51,16,33,23.75,37.29,30ZM79,50.5c-.36-.49,0-.42,0,0Zm-16.65-.08c4.23-1,8.79-1.59,13.11-.81a11,11,0,0,1,3.5,1,1,1,0,0,1-.07.15,17.07,17.07,0,0,1-1.38,2.6c-6.85,9-22,7.94-33.46,4.22A65.1,65.1,0,0,1,62.38,50.42ZM58.06,29a5.71,5.71,0,0,1,.88.3,8.39,8.39,0,0,1-.69,3.35,4.51,4.51,0,0,1-.47.61,9.65,9.65,0,0,1-9.17,2.89,15.75,15.75,0,0,1-6.76-3.27C47.1,30,53.08,27.83,58.06,29Z'
      transform='translate(-24.12 -9.92)'
    />
  </svg>
);
export default SVGComponent;
